/* noto-sans-kr-regular - korean */

@font-face {
    font-display: swap;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/noto-sans-kr-v27-korean-regular.woff2') format('woff2');
}

/* noto-sans-kr-500 - korean */

@font-face {
    font-display: swap;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: url('../../fonts/noto-sans-kr-v27-korean-500.woff2') format('woff2');
}

/* noto-sans-kr-700 - korean */

@font-face {
    font-display: swap;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/noto-sans-kr-v27-korean-700.woff2') format('woff2');
}
